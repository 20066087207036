import { match } from 'ts-pattern'
import { ScanAnalysisResponse } from '../utils/api-client/user-platform-api-schemas.ts'
import * as styles from './analysis-status-badge.css.ts'

type AnalysisStatus = ScanAnalysisResponse['current_state']['state']

type AnalysisStatusBadgeProps = {
  variant: AnalysisStatus
  style?: any
}

export function AnalysisStatusBadge({ variant, ...props }: AnalysisStatusBadgeProps) {
  if (variant === 'completed_results' || variant === 'completed_no_results') return null

  const text = match(variant)
    .with('in_progress', () => 'In Progress')
    .with('failed', () => 'Failed')
    .with('queued', () => 'Queued')
    .with('skipped', () => 'Skipped')
    .exhaustive()

  return (
    <span className={styles.badge[variant as keyof typeof styles.badge]} {...props}>
      {text}
    </span>
  )
}
