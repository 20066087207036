import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { match } from 'ts-pattern'
import { AnalysisStatusBadge } from '../components/analysis-status-badge.tsx'
import { FindingsTablePure } from '../components/findings-table.tsx'
import { Spinner } from '../components/spinner'
import { Pagination } from '../components/table'
import { utilities } from '../main.css.ts'
import {
  useGetChangesetsForFix,
  useGetFindingArticle,
  useGetFindings,
  useGetFixesForFinding,
  useGetScanAnalysis,
  useGetScanAnalysisV1,
  usePostPatch,
} from '../utils/api-client/user-platform-api-hooks'
import {
  AnalyzedFinding,
  Changeset,
  Fix,
  RepositoryType,
  ScanAnalysis,
  ScanAnalysisResponse,
  Tool,
} from '../utils/api-client/user-platform-api-schemas'
import { useTheme } from '../utils/higher-order-components/with-theme'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import * as styles from './analysis-details-page.css'

export function AnalysisDetailsPage() {
  const { analysisId, scanId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  if (!analysisId || !scanId) {
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, or scanId')
  }

  const { theme } = useTheme()
  const { handleAddToastWithTimeout } = useAddToast()
  const { data: scanAnalysis } = useGetScanAnalysis({ analysisId, scanId })
  const { data: scanAnalysisV1 } = useGetScanAnalysisV1({
    analysisId,
  })

  const DEFAULT_PAGE_INDEX = 0
  const DEFAULT_PAGE_SIZE = 10
  const VALID_PAGE_SIZES = [10, 25, 50]

  const pagination = (() => {
    const pageIndex = Number(searchParams.get('page_number') ?? DEFAULT_PAGE_INDEX)
    const pageSize = Number(searchParams.get('page_size') ?? DEFAULT_PAGE_SIZE)

    return { pageIndex, pageSize }
  })()

  const handlePaginationWithTotal = (pagination: Pagination) => {
    if (pagination.pageIndex === DEFAULT_PAGE_INDEX && pagination.pageSize === DEFAULT_PAGE_SIZE) {
      navigate('', { replace: true })
      return
    }

    let params = new URLSearchParams({
      page_number: pagination.pageIndex.toString(),
      page_size: pagination.pageSize.toString(),
    })

    if (totalResults > 0) {
      const isPageSizeValid = VALID_PAGE_SIZES.includes(pagination.pageSize)
      const isPageIndexValid = pagination.pageIndex * pagination.pageSize < totalResults

      if (!isPageSizeValid && !isPageIndexValid) {
        params = new URLSearchParams({
          page_number: DEFAULT_PAGE_INDEX.toString(),
          page_size: DEFAULT_PAGE_SIZE.toString(),
        })
      } else if (!isPageSizeValid) {
        params = new URLSearchParams({
          page_number: pagination.pageIndex.toString(),
          page_size: DEFAULT_PAGE_SIZE.toString(),
        })
      } else if (!isPageIndexValid) {
        params = new URLSearchParams({
          page_number: DEFAULT_PAGE_INDEX.toString(),
          page_size: pagination.pageSize.toString(),
        })
      }
    }

    navigate(`?${params.toString()}`, { replace: true })
  }

  const { data: findingsPage } = useGetFindings({
    analysisId,
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })
  const findings = findingsPage?._embedded?.items
  const totalResults = findingsPage?.total ?? 0

  const [selectedFindingId, setSelectedFindingId] = useState<string | undefined>(undefined)
  const [selectedFindingIdForFix, setSelectedFindingIdForFix] = useState<string | undefined>(undefined)
  const {
    data: findingArticle,
    error,
    isError: isFindingArticleError,
  } = useGetFindingArticle({
    analysisId,
    findingId: selectedFindingId!,
    enabled: !!selectedFindingId,
  })
  const selectedFinding = findings?.find(finding => finding.id === selectedFindingId)
  const selectedFindingMarkdown = findingArticle
  useEffect(() => {
    if (isFindingArticleError) {
      setSelectedFindingId(undefined)
      handleAddToastWithTimeout({
        message: <p>Failed loading finding triage article: {error?.message ?? 'Unknown error'}</p>,
        variant: 'error',
      })
      if (error.bodyAsText) {
        handleAddToastWithTimeout({
          message: <pre>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    }
  }, [isFindingArticleError, error, handleAddToastWithTimeout])

  const { data: paginatedFixes } = useGetFixesForFinding({
    analysisId,
    findingId: selectedFindingIdForFix!,
    enabled: !!selectedFindingIdForFix,
  })
  const fixes = paginatedFixes?._embedded?.items
  const fix = fixes?.[0]

  const { data: paginatedChangesets } = useGetChangesetsForFix({
    fixId: fix?.id!,
    enabled: !!fix,
  })
  const changesets = paginatedChangesets?._embedded?.items

  const postPatchMutation = usePostPatch({
    onSuccess: () => {
      handleAddToastWithTimeout({
        message: <>Request to send patch received successfully!</>,
        variant: 'success',
      })
      setSelectedFindingIdForFix(undefined)
    },
    onError: () => {
      handleAddToastWithTimeout({
        message: <>Failed to send patch.</>,
        variant: 'error',
      })
    },
  })

  return (
    <>
      <AnalysisDetailsPagePure
        theme={theme}
        analysisState={scanAnalysisV1?.current_state.state}
        scanAnalysis={scanAnalysis}
        findings={findings}
        totalFindings={findingsPage?.total}
        pagination={pagination}
        handlePaginationChange={handlePaginationWithTotal}
        selectedFinding={selectedFinding}
        selectedFindingMarkdown={selectedFindingMarkdown}
        setSelectedFindingId={setSelectedFindingId}
        selectedFindingIdForFix={selectedFindingIdForFix}
        setSelectedFindingIdForFix={setSelectedFindingIdForFix}
        fixes={fixes}
        changesets={changesets}
        handleSendPatch={() =>
          postPatchMutation.mutate({
            analysisId,
            createPatchRequest: {
              findings: [selectedFindingIdForFix!],
            },
          })
        }
        isSendPatchLoading={postPatchMutation.isPending}
      />
    </>
  )
}

export function AnalysisDetailsPagePure({
  analysisState,
  scanAnalysis,
  findings,
  totalFindings,
  pagination,
  handlePaginationChange = () => {},
  selectedFinding = undefined,
  selectedFindingMarkdown = '',
  setSelectedFindingId = () => {},
  selectedFindingIdForFix = undefined,
  setSelectedFindingIdForFix = () => {},
  fixes = [],
  changesets = [],
  handleSendPatch,
  isSendPatchLoading,
  theme = 'dark',
}: {
  analysisState?: ScanAnalysisResponse['current_state']['state']
  scanAnalysis?: ScanAnalysis
  findings?: AnalyzedFinding[]
  totalFindings?: number
  pagination?: Pagination
  handlePaginationChange?: (pagination: Pagination) => void
  selectedFinding?: AnalyzedFinding
  selectedFindingMarkdown?: string
  setSelectedFindingId?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedFindingIdForFix?: string | undefined
  setSelectedFindingIdForFix?: React.Dispatch<React.SetStateAction<string | undefined>>
  fixes?: Fix[]
  changesets?: Changeset[]
  handleSendPatch?: () => void
  isSendPatchLoading?: boolean
  theme?: ReturnType<typeof useTheme>['theme']
}) {
  const totalFixes = findings?.reduce((total, finding) => total + (finding._embedded?.fixes?.total ? 1 : 0), 0)

  return (
    <div className={styles.analysisDetailsContainer}>
      <div style={{ margin: '24px 0 24px' }} className={styles.breadcrumbContainer}>
        <Link to="/scans" className={styles.breadcrumbLink}>
          All scans
        </Link>{' '}
        <span className={styles.breadcrumbSeparator}>/ Scan analysis</span>
      </div>
      <header className={styles.analysisDetailsHeader} role="banner">
        {scanAnalysis ? (
          <ScanInfo scanAnalysis={scanAnalysis} theme={theme} />
        ) : (
          <Spinner label="Loading scan analysis..." />
        )}
        <section className={styles.repoInfoContainer} aria-labelledby="repo-info-header">
          <h2 id="repo-info-header" className={utilities.visuallyHidden}>
            Repository Information
          </h2>
          {scanAnalysis?.repository_display_name ? (
            <RepositoryInformation
              type={scanAnalysis.repository_type}
              displayRepositoryName={repositoryNameToDisplay(scanAnalysis.repository_type)(
                scanAnalysis.repository_display_name
              )}
              displayRepositoryIcon={repositoryIconToDisplay(scanAnalysis.repository_type)}
              analysisState={analysisState}
            />
          ) : (
            <Spinner label="Loading user and repository details..." />
          )}
          {scanAnalysis?.tool && (
            <TriageCompositionBar
              totalFixes={totalFixes}
              totalFindings={totalFindings}
              tool={scanAnalysis.tool}
              analysisState={analysisState}
            />
          )}
        </section>
        <section className={styles.snykLinkContainer} role="complementary" aria-labelledby="metrics-header">
          <h2 id="metrics-header" className={utilities.visuallyHidden}>
            Metrics and External Link
          </h2>
          {scanAnalysis?.html_url && (
            <a href={scanAnalysis?.html_url} target="_blank" rel="noopener noreferrer" className={styles.snykLink}>
              View in {mapToolToLogoAndName(scanAnalysis.tool, theme).name}
              <ExternalLinkIcon className={styles.externalLinkIcon} />
            </a>
          )}
          <FindingsMetrics totalFixes={totalFixes} totalFindings={totalFindings} analysisState={analysisState} />
        </section>
      </header>
      <FindingsTablePure
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        findings={findings}
        totalFindings={totalFindings}
        selectedFinding={selectedFinding}
        selectedFindingMarkdown={selectedFindingMarkdown}
        setSelectedFindingId={setSelectedFindingId}
        selectedFindingIdForFix={selectedFindingIdForFix}
        setSelectedFindingIdForFix={setSelectedFindingIdForFix}
        selectedFindingFixes={fixes}
        selectedFindingChangesets={changesets}
        theme={theme}
        handleSendPatch={handleSendPatch}
        isSendPatchLoading={isSendPatchLoading}
      />
    </div>
  )
}

export const mapToolToLogoAndName = (tool: Tool, mode: 'light' | 'dark') => {
  const suffix = mode === 'light' ? 'Lt' : 'Dk'
  return match(tool)
    .with('APPSCAN', () => ({ name: 'AppScan', logoHref: '/SastToolVisualAssets/AppScan-Icon.png' }))
    .with('CHECKMARX', () => ({ name: 'Checkmarx', logoHref: '/SastToolVisualAssets/Checkmarx-Icon.svg' }))
    .with('CODEQL', () => ({ name: 'CodeQL', logoHref: '/SastToolVisualAssets/CodeQL-Logo.svg' }))
    .with('CONTRAST', () => ({ name: 'Contrast', logoHref: `/SastToolVisualAssets/Contrast-Icon-${suffix}.svg` }))
    .with('DEFECT_DOJO', () => ({ name: 'DefectDojo', logoHref: '/SastToolVisualAssets/DefectDojo-Icon.svg' }))
    .with('PIXEE', () => ({ name: 'Pixee', logoHref: '/SastToolVisualAssets/Pixee-Icon.svg' }))
    .with('SEMGREP', () => ({ name: 'Semgrep', logoHref: '/SastToolVisualAssets/Semgrep-Icon.svg' }))
    .with('SNYK', () => ({ name: 'Snyk', logoHref: `/SastToolVisualAssets/Snyk-Icon-${suffix}.svg` }))
    .with('SONAR', () => ({ name: 'SonarQube', logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg` }))
    .exhaustive()
}

const ScanInfo = ({ scanAnalysis, theme }: { scanAnalysis: ScanAnalysis; theme: 'light' | 'dark' }) => {
  const { name, logoHref } = mapToolToLogoAndName(scanAnalysis.tool, theme)
  const scanDate = DateTime.fromISO(scanAnalysis.imported_at)

  return (
    <div className={styles.scanInfoContainer}>
      <h2 className={utilities.visuallyHidden}>Scan Information</h2>
      <div className={styles.toolLogoContainer}>
        <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
      </div>
      <div className={styles.dateContainer}>
        <p className={styles.dateText}>{scanDate.toFormat('M/d/yy')}</p>
        <p className={styles.timeText}>{scanDate.toFormat('hh:mm:ss a')}</p>
      </div>
      <p className={styles.branchTag} title={scanAnalysis.branch ?? ''}>
        {scanAnalysis.branch && scanAnalysis.branch.length > 25
          ? `${scanAnalysis.branch.slice(0, 22)}...`
          : (scanAnalysis.branch ?? scanAnalysis.sha)}
      </p>
    </div>
  )
}

type repositoryDisplayName = { owner: string; name: string } | { name: string }
type DisplayRepositoryName = () => repositoryDisplayName
type RepositoryNameToDisplay = (repositoryName: string) => DisplayRepositoryName
type RepositoryNameToDisplayWithType = (type: RepositoryType) => RepositoryNameToDisplay
export const repositoryNameToDisplay: RepositoryNameToDisplayWithType = type => repositoryName => () => {
  if (type === 'github') {
    const [owner, name] = repositoryName.split('/')
    invariant(owner, 'Owner is required for GitHub repository names')
    invariant(name, 'Name is required for GitHub repository names')
    return { owner, name }
  }

  const name = repositoryName
  invariant(name, 'Name is required for generic Git repository names')
  return { name }
}

type DisplayRepositoryIcon = () => typeof MarkGithubIcon | typeof GitMergeIcon
type RepositoryIconToDisplayWithType = (type: RepositoryType) => DisplayRepositoryIcon
export const repositoryIconToDisplay: RepositoryIconToDisplayWithType = type => () => {
  return type === 'github'
    ? () => <MarkGithubIcon aria-label="GitHub icon" className={styles.scmIcon} />
    : () => <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
}

const RepositoryInformation: React.FC<{
  type: RepositoryType
  analysisState?: ScanAnalysisResponse['current_state']['state']
  displayRepositoryName: DisplayRepositoryName
  displayRepositoryIcon: DisplayRepositoryIcon
}> = ({ type, analysisState, displayRepositoryName, displayRepositoryIcon }) => {
  const Icon = displayRepositoryIcon()
  const displayName = displayRepositoryName()

  return (
    <>
      {'owner' in displayName && <p className={styles.accountLoginText}>{displayName.owner}</p>}
      <div className={styles.repositoryNameContainer}>
        <Icon />
        <p className={styles.repositoryName[type]}>{displayName.name}</p>
        {analysisState && analysisState !== 'completed_results' && analysisState !== 'completed_no_results' && (
          <AnalysisStatusBadge variant={analysisState} />
        )}
      </div>
    </>
  )
}

const TriageCompositionBar = ({
  totalFixes,
  totalFindings,
  tool,
  analysisState,
}: {
  totalFixes?: number
  totalFindings?: number
  tool: Tool
  analysisState?: ScanAnalysisResponse['current_state']['state']
}) => (
  <div
    role="img"
    aria-label={`${totalFixes} out of ${totalFindings} findings had available fixes`}
    className={styles.fixesContainer}
  >
    {(analysisState === 'completed_results' || analysisState === 'completed_no_results') &&
    totalFixes !== undefined &&
    totalFindings !== undefined ? (
      <div className={styles.bar}>
        {totalFixes === 0 ? (
          <div
            className={classNames(styles.barFilled[tool], styles.barEmpty, styles.roundedBar)}
            style={{ width: '100%' }}
          />
        ) : totalFixes === totalFindings ? (
          <div className={classNames(styles.barFilled[tool], styles.roundedBar)} style={{ width: '100%' }} />
        ) : (
          <>
            <div className={styles.barFilled[tool]} style={{ width: `${(totalFixes / totalFindings) * 100}%` }} />
            <div
              className={classNames(styles.barFilled[tool], styles.barEmpty)}
              style={{ width: `${((totalFindings - totalFixes) / totalFindings) * 100}%` }}
            />
          </>
        )}
      </div>
    ) : (
      <div className={styles.inProgressBar} role="progressbar" aria-label="Analysis in progress">
        <div className={styles.progressSquare} />
      </div>
    )}
    {totalFixes !== undefined && totalFixes > 0 && (
      <p className={styles.fixesText}>
        {totalFixes}/{totalFindings}{' '}
        <span className={styles.fixesTextLabel}>findings have available fixes (current table page)</span>
      </p>
    )}
  </div>
)

const FindingsMetrics = ({
  totalFixes,
  totalFindings,
  analysisState,
}: {
  totalFixes?: number
  totalFindings?: number
  analysisState?: ScanAnalysisResponse['current_state']['state']
}) => {
  const shouldShowMetrics = analysisState === 'completed_results' || analysisState === 'completed_no_results'

  return (
    <div className={styles.metricsContainer} role="group" aria-label="Findings Metrics">
      <output id="findings-count" className={styles.metricValue}>
        {shouldShowMetrics && totalFindings ? totalFindings : '-'}
      </output>
      <label htmlFor="findings-count" className={styles.metricLabel}>
        findings
      </label>
      <output id="fix-coverage" className={styles.metricValue}>
        {shouldShowMetrics && totalFixes !== undefined && totalFindings !== undefined
          ? totalFindings === 0
            ? '0%'
            : `${Math.round((totalFixes / totalFindings) * 100)}%`
          : '-'}
      </output>
      <label htmlFor="fix-coverage" className={styles.metricLabel}>
        fix coverage
      </label>
    </div>
  )
}
